// @flow

import React, { useEffect, useMemo, useState, type Node } from 'react';
import Head from 'next/head';
import { RelayEnvironmentProvider } from '@adeira/relay';
import { useRouter } from 'next/router';
import { RecoilRoot } from 'recoil';
import '../public/base.css';

import { initSentry } from '../src/setup/initSentry';
import i18nInit from '../translations/init';
import { pageview as GAPageview } from '../src/googleAnalytics';
import createRelayEnvironment from '../src/createRelayEnvironment';
import { CurrentUserContext } from '../src/CurrentUserContext';

initSentry();

export default function MyApp(props: any): Node {
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', GAPageview);
    return () => {
      router.events.off('routeChangeComplete', GAPageview);
    };
  }, [router]);

  const { id, email, name } = props.pageProps?.user ?? {};
  const [locale, setLocale] = useState<string>(props.pageProps.locale);

  i18nInit(locale);
  const currentUser = useMemo(
    () => ({ id, email, name, locale, setLocale }),
    [id, email, locale, name],
  );

  const { Component, pageProps, err } = props;
  const Layout = Component.Layout ?? React.Fragment;
  const layoutProps = Component.Layout ? { header: Component.Header } : {};

  return (
    <RelayEnvironmentProvider environment={createRelayEnvironment(undefined)}>
      <RecoilRoot>
        <CurrentUserContext.Provider value={currentUser}>
          <Head>
            <title>Fitlr</title>
            <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
          </Head>

          <Layout {...layoutProps}>
            <Component {...pageProps} err={err} />
          </Layout>
        </CurrentUserContext.Provider>
      </RecoilRoot>
    </RelayEnvironmentProvider>
  );
}
