// @flow

import { IntlVariations, init as fbtInit } from 'fbt';

export default function init(appLocale: string) {
  const locale = getLocale(appLocale);

  fbtInit({
    translations: getTranslations(locale),
    hooks: {
      getViewerContext: () => ({
        GENDER: IntlVariations.GENDER_UNKNOWN,
        locale,
      }),
    },
  });
}

function getTranslations(locale: string) {
  if (locale === 'cs_CZ') {
    return require('./out/cs_CZ.json');
  }
  return require('./out/en.json');
}

function getLocale(appLocale: string) {
  if (appLocale === 'cs-cz' || appLocale === 'cs-CZ') {
    return 'cs_CZ';
  }
  return 'en';
}
