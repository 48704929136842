// @flow strict

import React, { type Node } from 'react';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

const init = (): Node => {
  if (GA_TRACKING_ID == null) {
    return null;
  }
  return (
    <>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
};
export default init;

// eslint-disable-next-line no-undef
const isGADefined = () => typeof window?.gtag === 'function';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (!isGADefined) {
    return;
  }

  // eslint-disable-next-line no-undef
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
type Event = {
  +action: string,
  +category: string,
  +label: string,
  +value: number,
};
export const event = ({ action, category, label, value }: Event) => {
  if (!isGADefined) {
    return;
  }

  // eslint-disable-next-line no-undef
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
