// @flow strict-local

import {
  createEnvironment,
  createNetworkFetcher,
  type Environment,
  type RecordMap,
} from '@adeira/relay';

export default function createRelayEnvironment(initialData: ?RecordMap): Environment {
  const resource = '/api/graphql';
  const fetchFn = createNetworkFetcher(resource, {
    'X-Client': 'https://fitlr.com',
  });

  return createEnvironment({
    fetchFn,
    records: initialData,
  });
}
