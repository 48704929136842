// @flow

import { createContext, type Context } from 'react';

type CurrentUser = {
  +id: ?string,
  +email: ?string,
  +locale: string,
  +name: ?string,
  +setLocale: (string) => void,
};

export const CurrentUserContext: Context<CurrentUser> = createContext<CurrentUser>({
  id: null,
  email: null,
  locale: 'en-US',
  name: null,
  setLocale: () => {},
});
